import { currentTheme } from './utils/currentTheme';

const elementMapping = {
    Dawn: '.shopify-section-group-footer-group footer, #shopify-section-footer footer',
    Showcase: '#shopify-section-footer',
};

const selector = elementMapping[currentTheme];
const possibleElementsSelectors = [...Object.values(elementMapping), 'body'];
const possibleElements = document.querySelectorAll<HTMLDivElement>(
    selector || possibleElementsSelectors.join(', ')
);

const footerElement: HTMLDivElement | null = possibleElements[possibleElements.length - 1];

footerElement && appendFooterBranding(footerElement);
ensureStyling();

function appendFooterBranding(footerElement: HTMLDivElement) {
    if (!footerElement || footerElement.dataset.loaded) {
        return;
    }

    let brandingElement = document.querySelector<HTMLDivElement>('.pi-footer__branding');

    if (!brandingElement) {
        brandingElement = document.createElement('div');
        brandingElement.className = `pi-footer__branding`;
        brandingElement.innerHTML = `
		<a href="https://pixify.io?utm_campaign=possibleby&utm_medium=shopify&utm_source=onlinestore" class="pi-footer__branding__link" rel="nofollow" target="__blank">
			Made possible by <strong>Pixify</strong>
		</a>
	`;
    }

    footerElement.dataset.loaded = 'true';
    footerElement.appendChild(brandingElement);
}

function ensureStyling() {
    let brandingElement = document.querySelector<HTMLDivElement>('.pi-footer__branding');

    if (!brandingElement) return;

    brandingElement.classList.add(`pi-footer__branding--${currentTheme.toLowerCase()}`);

    // Avoid having to manage styling two places
    brandingElement.innerHTML = `
        <style>
            .pi-footer__branding { padding: 2rem 1em; text-align: center; position: relative; }
            .pi-footer__branding__link { font-size: 12px; color: currentColor; text-decoration: none; font-weight: normal; border: none; }
            .pi-footer__branding__link:hover { text-decoration: underline; }
            .pi-footer__branding__link strong { font-weight: 600; }
            .pi-footer__branding--dawn { padding: 1em; }
            .pi-footer__branding--unknown { padding: 1em; }
        </style>
        ${brandingElement.innerHTML}
    `;
}
