type Themes = "Dawn" | "Showcase";

export const themes: Record<number, Themes> = {
  887: "Dawn",
  677: "Showcase",
};

export const themeId = Shopify?.theme?.theme_store_id as Number;
export const currentTheme = themes[themeId as keyof typeof themes] || "unknown";

export const isTheme = (themeName = "") => {
  return currentTheme == themeName;
};

type SelectorMethods = "before" | "after" | "append" | "prepend";
type Selector = string[];
type ThemeSelectors = Record<Themes, Selector[]>;

export function mountToThemeElement(
  element: HTMLElement,
  selectors: ThemeSelectors
) {
  const themeSelectors = selectors[currentTheme] || [];

  for (let i = 0; i < themeSelectors.length; i++) {
    const targetSelector = themeSelectors[i];
    const [selector, positionMethod = "before"] = targetSelector;
    const targetElement = document.querySelector(selector);

    if (targetElement) {
      targetElement[positionMethod as SelectorMethods](element);
      return targetElement;
      break;
    }
  }
}

export function findThemeElement(selectors: Record<Themes, string[]>) {
  const themeSelectors = selectors[currentTheme] || [];

  for (let i = 0; i < themeSelectors.length; i++) {
    const targetSelector = themeSelectors[i];
    const targetElement = document.querySelector(targetSelector);

    if (targetElement) {
      return targetElement;
    }
  }
}
